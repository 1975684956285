<template>
  <div>
    <b-row>
      <b-col sm="12">
        <card-statistics-group
          icon="CpuIcon"
          statistic="86%"
          :statistic-title="$t('stats')"
          :statistics-items="statisticsItems"
        />
      </b-col>
    </b-row>

    <!-- Gráfico de Egresos con Drill-Down -->
    <b-row>
      <b-col sm="6">
        <b-card v-if="incomeVsExpensesSeries[0] > 0">
          <b-card-title>{{ $t('expenses') }}</b-card-title>
          <apexchart
            height="300"
            type="pie"
            :options="expensesOptions"
            :series="currentExpensesSeries"
            @dataPointSelection="handleExpenseClick"
          />
          <b-button
            v-if="expenseLevel > 0"
            variant="link"
            @click="goBackExpenseLevel"
          >
            ← {{ $t('return') }}
          </b-button>
        </b-card>
      </b-col>

      <b-col sm="6">
        <b-card>
          <b-card-title>{{ $t('expenses_trend') }}</b-card-title>
          <apexchart
            height="300"
            type="line"
            :options="expensesLineOptions"
            :series="expensesLineSeries"
          />
        </b-card>
      </b-col>
    </b-row>

    <!-- Gráfico de Ingresos con Drill-Down -->
    <b-row>
      <b-col sm="6">
        <b-card v-if="incomeVsExpensesSeries[1] > 0">
          <b-card-title>{{ $t('incomes') }}</b-card-title>
          <apexchart
            height="300"
            type="pie"
            :options="incomesOptions"
            :series="currentIncomesSeries"
            @dataPointSelection="handleIncomeClick"
          />
          <b-button
            v-if="incomeLevel > 0"
            variant="link"
            @click="goBackIncomeLevel"
          >
            ← {{ $t('return') }}
          </b-button>
        </b-card>
      </b-col>

      <b-col sm="6">
        <b-card>
          <b-card-title>{{ $t('incomes_trend') }}</b-card-title>
          <apexchart
            height="300"
            type="line"
            :options="incomesLineOptions"
            :series="incomesLineSeries"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Vue from 'vue'
import CardStatisticsGroup from '@/components/cards/CardStatisticsGroup.vue'

export default {
  components: {
    CardStatisticsGroup,
  },
  data() {
    return {
      statisticsItems: [
        {
          id: 'incomes',
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '',
          subtitle: this.$t('incomes'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          id: 'expenses',
          icon: 'DollarSignIcon',
          color: 'light-danger',
          title: '',
          subtitle: this.$t('expenses'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          id: 'balance',
          icon: 'DollarSignIcon',
          color: 'light-warning',
          title: '',
          subtitle: this.$t('balance'),
          customClass: 'mb-2 mb-xl-0',
        },
        // {
        //   id: 'additivesAndChanges',
        //   icon: 'BoxIcon',
        //   color: 'light-danger',
        //   title: '',
        //   subtitle: this.$t('additivesAndChanges'),
        //   customClass: 'mb-2 mb-sm-0',
        // },
        // {
        //   id: 'direcCost',
        //   icon: 'BriefcaseIcon',
        //   color: 'light-success',
        //   title: '',
        //   subtitle: this.$t('directCost'),
        //   customClass: '',
        // },
      ],
      expenseLevel: 0,
      incomeLevel: 0,
      incomesOptions: {
        chart: {
          id: 'incomes-chart',
        },
        legend: {
          position: 'bottom',
        },
        labels: ['Incomes'],
        colors: ['#6e32b4', '#28c76f', '#ff9f43', '#ea5455', '#7367f0'],
        dataLabels: {
          formatter(val, options) {
            return `$${Vue.options.filters.money(options.w.config.series[options.seriesIndex])}`
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter(value) {
              return `$${Vue.options.filters.money(value)}`
            },
            title: {
              formatter: title => title,
            },
          },
        },
      },
      expensesLineOptions: {
        chart: {
          id: 'expenses-line-chart',
        },
        xaxis: {
          categories: [
            this.$t('january'),
            this.$t('february'),
            this.$t('march'),
            this.$t('april'),
            this.$t('may'),
            this.$t('june'),
            this.$t('july'),
            this.$t('august'),
            this.$t('september'),
            this.$t('october'),
            this.$t('november'),
            this.$t('december'),
          ],
        },
        stroke: {
          curve: 'smooth',
        },
        tooltip: {
          enabled: true,
          y: {
            formatter(value) {
              return `$${Vue.options.filters.money(value)}`
            },
          },
        },
      },
      incomesLineOptions: {
        chart: {
          id: 'incomes-line-chart',
        },
        xaxis: {
          categories: [
            this.$t('january'),
            this.$t('february'),
            this.$t('march'),
            this.$t('april'),
            this.$t('may'),
            this.$t('june'),
            this.$t('july'),
            this.$t('august'),
            this.$t('september'),
            this.$t('october'),
            this.$t('november'),
            this.$t('december'),
          ],
        },
        stroke: {
          curve: 'smooth',
        },
        tooltip: {
          enabled: true,
          y: {
            formatter(value) {
              return `$${Vue.options.filters.money(value)}`
            },
          },
        },
      },
      expensesLineSeries: [
        {
          name: this.$t('expenses'),
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      loading: false,
      expenses: [],
      expenseTypes: [],
      expenseTypesNames: [],
      expenseTypesIds: [],
      expenseTypeHistory: [],
    }
  },
  computed: {
    ...mapGetters('incomes', ['incomes']),
    ...mapGetters('projects', ['project']),
    expensesOptions() {
      return {
        chart: {
          id: 'expenses-chart',
        },
        legend: {
          position: 'bottom',
        },
        labels: this.expenseTypesNames,
        colors: ['#bb81fc', '#6e32b4', '#28c76f', '#ff9f43', '#ea5455'],
        dataLabels: {
          formatter(val, options) {
            return `$${Vue.options.filters.money(options.w.config.series[options.seriesIndex])}`
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter(value) {
              return `$${Vue.options.filters.money(value)}`
            },
            title: {
              formatter: title => title,
            },
          },
        },
      }
    },
    incomesLineSeries() {
      const currentYear = new Date().getFullYear()
      const monthlyIncomes = Array(12).fill(0)
      this.incomes.forEach(income => {
        const incomeDate = new Date(income.income_date)
        if (incomeDate.getFullYear() === currentYear) {
          const monthIndex = incomeDate.getMonth()
          monthlyIncomes[monthIndex] += parseFloat(income.total_amount)
        }
      })
      return [
        {
          name: this.$t('incomes'),
          data: monthlyIncomes,
        },
      ]
    },
    currentExpensesSeries() {
      const totals = []
      const expenseType = this.expenseTypeSelected
      if (!expenseType) {
        this.expenseTypes.forEach(e => {
          let total = 0
          const subIDs = this.getAllSubcategoryIds(e)
          this.expenses.forEach(exp => {
            if (exp.expense_type_catalog.id === e.id || subIDs.includes(exp.expense_type_catalog.id)) {
              total += parseFloat(exp.total)
            }
          })
          totals.push(total)
        })
      } else {
        const subCategories = expenseType?.subcategories || []
        subCategories?.forEach(sub => {
          console.log(sub)
          let total = 0
          const subIDs = this.getAllSubcategoryIds(sub)
          this.expenses.forEach(exp => {
            if (subIDs.includes(exp.expense_type_catalog.id)) {
              total += parseFloat(exp.total)
            }
          })
          totals.push(total)
        })
      }
      return totals
    },
    currentIncomesSeries() {
      return [this.project.incomes_total]
    },
    incomeVsExpensesSeries() {
      return [
        parseFloat(this.project.expenses_total),
        parseFloat(this.project.incomes_total),
      ]
    },
    expenseTypeSelected() {
      return this.expenseTypeHistory[this.expenseTypeHistory.length - 1] || null
    },
  },
  mounted() {
    this.loading = true
    this.fetchExpensesTypeCatalogs(this.$route.params.projectId)
      .then(categories => {
        this.expenseTypes = categories?.data || []
        this.expenseTypesNames = categories?.data?.map(category => (
          category.name
        ))
      })
      .finally(() => {
        // this.getAllSubcategoryIds()
        this.fetchExpenses({
          projectId: this.$route.params.projectId,
        }).then(expenses => {
          this.expenses = expenses?.data || []
          // Group expenses by month and calculate totals
          const monthlyExpenses = Array(12).fill(0)
          const currentYear = new Date().getFullYear()
          this.expenses.forEach(expense => {
            const expenseDate = new Date(expense.expense_date)
            if (expenseDate.getFullYear() === currentYear) {
              const monthIndex = expenseDate.getMonth()
              monthlyExpenses[monthIndex] += parseFloat(expense.total)
            }
          })
          // Assign the monthly totals to expensesLineSeries
          this.expensesLineSeries = [
            {
              name: this.$t('expenses'),
              data: monthlyExpenses,
            },
          ]// [0].data = monthlyExpenses // monthlyExpenses.map(total => parseFloat(total.toFixed(2)))
        }).finally(() => {
          this.loading = false
        })
      })
    this.fetchIncomes({
      projectId: this.$route.params.projectId,
    })
  },
  created() {
    this.fetchProject(this.$route.params.projectId).then(response => {
      const [incomes, expenses, balance] = this.statisticsItems
      incomes.title = `$${this.$options.filters.money(response.incomes_total)}`
      expenses.title = `$${this.$options.filters.money(
        response.expenses_total,
      )}`
      balance.title = `$${this.$options.filters.money(
        response.incomes_total - response.expenses_total,
      )}`
    })
  },
  methods: {
    ...mapActions('incomes', ['fetchIncomes']),
    ...mapActions('projects', ['fetchProject']),
    ...mapActions('expenses', ['fetchExpenses']),
    ...mapActions('groupedExpenses', ['fetchExpensesTypeCatalogs', 'fetchSubExpenseTypeCatalog']),
    getAllSubcategoryIds(category) {
      let ids = []
      if (category && category.id) {
        ids.push(category.id) // Añadir el ID de la categoría actual solo si existe
      }
      if (category?.subcategories && category.subcategories.length > 0) {
        category.subcategories.forEach(subcat => {
          ids = ids.concat(this.getAllSubcategoryIds(subcat)) // Llamada recursiva para añadir IDs de subcategorías
        })
      }
      return ids
    },
    handleExpenseClick(event, chartContext, opts) {
      const nameSelected = this.expenseTypesNames[opts.dataPointIndex]
      console.log(nameSelected)
      const expenseTypeSelected = this.expenseTypeSelected ? this.expenseTypeSelected?.subcategories?.find(expenseType => expenseType.name === nameSelected) : this.expenseTypes.find(expenseType => expenseType.name === nameSelected)
      console.log(expenseTypeSelected)
      this.expenseTypeHistory.push(expenseTypeSelected)
      this.expenseTypesNames = this.expenseTypeSelected ? this.expenseTypeSelected?.subcategories?.map(sub => sub.name) : this.expenseTypes.map(expenseType => expenseType.name)
      this.expenseLevel += 1
      // this.updateExpenseLabels()
    },
    handleIncomeClick() {
      if (this.incomeLevel < 4) {
        this.incomeLevel += 1
        this.updateIncomeLabels()
      }
    },
    goBackExpenseLevel() {
      this.expenseTypeHistory.pop()
      this.expenseTypesNames = this.expenseTypeSelected ? this.expenseTypeSelected?.subcategories?.map(sub => sub.name) : this.expenseTypes.map(expenseType => expenseType.name)
      this.expenseLevel -= 1
    },
    goBackIncomeLevel() {
      if (this.incomeLevel > 0) {
        this.incomeLevel -= 1
        this.updateIncomeLabels()
      }
    },
  },
}
</script>

<style></style>
